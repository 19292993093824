<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else class="mt-14 w-full" style="height: 600px; overflow: auto">
      <button
        class="bg-gray-800 py-2 px-3 rounded-md text-xs text-white"
        @click="$router.go(-1)"
      >
        &laquo; Back
      </button>
      <table class="shadow-lg bg-white w-full mt-3 relative border-collapse">
        <tr>
          <th
            class="text-l p-3 text-left"
            colspan="8"
            style="background: #dee6f2"
          >
            Bulk Sweep Transactions
          </th>
        </tr>
        <tr>
          <th class="bg-blue-100 text-xs whitespace-nowrap text-left px-8 py-2">
            Bulk Transfer Ref No
          </th>
          <th class="bg-blue-100 text-xs whitespace-nowrap text-left px-8 py-2">
            Transfer Amount
          </th>
          <th class="bg-blue-100 text-xs whitespace-nowrap text-left px-8 py-2">
            Transfer Date
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Destination Bank
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Destination Account Number
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Destination Account Name
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Status
          </th>
          <th
            class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
          >
            Re-query
          </th>
        </tr>
        <tr class="text-xs" v-for="(account, i) in history" :key="i">
          <td>
            {{ account.RequestRef || "N/A" }}
          </td>
          <td>{{ account.Amount || "N/A" }}</td>
          <td>
            {{
              moment(account.CreatedAt).format("DD MMM YYYY - h:mm:ss a") ||
              "N/A"
            }}
          </td>
          <td>
            {{ $route.params.bank }}
          </td>

          <td>
            {{ $route.params.acctno || "N/A" }}
          </td>
          <td>
            {{ $route.params.acctname || "N/A" }}
          </td>
          <td>
            {{ account.Status }}
          </td>
          <td v-if="account.Status === 'Transaction pending'">
            <button
              :class="isDisabled ? 'bg-gray-500 cursor-not-allowed' : ''"
              :disabled="isDisabled"
              class="block uppercase shadow bg-blue-900 leading-6 focus:shadow-outline focus:outline-none text-white text-xs py-1 w-40 rounded"
              @click="reQuery(account.RequestRef)"
            >
              Re-Query
            </button>
          </td>
          <td v-else>
            <button
              :disabled="true"
              class="block cursor-not-allowed uppercase shadow bg-transparent border-2 border-gray-400 leading-6 focus:shadow-outline focus:outline-none text-gray-500 text-xs py-1 w-40 rounded"
            >
              Re-Query
            </button>
          </td>
        </tr>
      </table>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      history: [],
      banks: [],
      isLoading: false,
      moment,
      isDisabled: false,
    };
  },
  methods: {
    async reQuery(payload) {
      this.isDisabled = true;
      try {
        const res = await this.$store.dispatch("RE_QUERY", payload);
        if (res.status) {
          alert(res.message);
        } else {
          alert("There was an error");
        }
        await this.getRequest();
      } catch (error) {
        console.log(error);
      } finally {
        this.isDisabled = false;
      }
    },
    getStatus(val) {
      switch (val) {
        case "100":
          return "New";
        case "102":
          return "Pending";
        case "00":
          return "Success";
        case "106":
          return "completed";
        case "101":
          return "Failed";
        case "107":
          return "Closed";
        case "96":
          return "Not Found";
        default:
          return "Error";
      }
    },
    async getRequest(payload) {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch(
          "SWEEP_REQUEST",
          this.$route.params.id
        );
        this.history = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getBanks() {
      this.isLoading = true;
      try {
        const banks = await this.$store.dispatch("GET_BANKS");
        this.banks = banks.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    getBankByCode(val) {
      const bank = this.banks.find((bank) => bank.provider_code === val);
      return (bank && bank.name) || "N/A";
    },
  },
  async created() {
    await this.getRequest();
    await this.getBanks();
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
